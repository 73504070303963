import { MessageTypeEnum, ProviderName, RecurringCampaignFrequencyEnum, RecurringCampaignStatusEnum } from '@shared/enums';
import { IHttpResponse, IRecurringCampaign } from '@shared/models';
import { getOxfordCommaString } from '@shared/services';
import { Button, ButtonVariantEnum, Chip, GlacierCheckbox, Info, Shimmer, ShimmerType, Static } from '@Wonder-Cave/ui';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosGet, axiosPatch } from '../../authAxios';
import { useNotifications } from '../../contexts/NotificationContext';
import { useCustomDataPoints } from '../../hooks/useCustomDataPoints';
import MessagePreview from '../shared/Form/MessagePreview';
import RecurringCampaignDeleteModal from './RecurringCampaignDeleteModal';
import RecurringCampaignPauseResumeModal from './RecurringCampaignPauseResumeModal';

interface IMetricCardProps {
  title: string;
  metric: number;
  loading: boolean;
  footer?: React.ReactNode;
  _export?: boolean;
  onExport?: () => void;
  exportLoading?: boolean;
  flat?: boolean;
  disabled?: boolean;
}

const MetricCard = ({ title, loading, metric, footer, _export, onExport, flat, exportLoading, disabled = false }: IMetricCardProps) => {
  return (
    <div
      className={`${flat ? '' : 'rounded-2.5xl'} bg-white flex flex-col items-center p-4 space-y-4 ${flat ? '' : 'w-44'
        }`}
      style={{ boxShadow: flat ? '' : '0px 0px 70px 0px #E9EBF0' }}
    >
      <h5 className="text-medium-gray">{title}</h5>
      {loading ? (
        <Shimmer type={ShimmerType.CUSTOM} className="w-16 h-4" />
      ) : (
        <div>
          {isFinite(metric) ? (flat ? metric : metric.toFixed(1)) : 0}
          {flat ? '' : '%'}
        </div>
      )}
      {!!onExport ? (
        <h4
          onClick={_export && !exportLoading && !disabled ? onExport : () => { }}
          className={`${(_export && !exportLoading && !disabled)
            ? 'cursor-pointer text-wc-blue decoration-wc-blue decoration-2 hover:underline'
            : `cursor-default text-medium-gray ${exportLoading ? 'animate-pulse' : ''}`
            }`}
        >
          <div className='flex items-center'>
            Export Data
            {disabled && <Info className="ml-1" text={`Unable to export ${title} data as it has been more than 30 days since campaign start date.`} />}
          </div>
        </h4>
      ) : (
        footer
      )}
    </div>
  );
};

export const RecurringCampaignsDetails = () => {
  const { id } = useParams<any>();
  const [recurringCampaign, setRecurringCampaign] = useState<any>();
  const navigate = useNavigate();
  const [showMetrics, setShowMetrics] = useState(false);
  const [clientName, setClientName] = useState('');
  const [providerName, setProviderName] = useState('');
  const [pauseLoading, setPauseLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const { addNotification } = useNotifications();
  const [{ data: customDataPointsData, loading: customDataPointsLoading }] = useCustomDataPoints({ personalized: true, skip: 0, take: 1000, isActive: true });
  const customDataPoints = customDataPointsData?.records ?? [];
  const isActive = [RecurringCampaignStatusEnum.ACTIVE, RecurringCampaignStatusEnum.SCHEDULING].includes(
    recurringCampaign?.status ?? RecurringCampaignStatusEnum.COMPLETE
  );
  useEffect(() => {
    if (id) {
      getRecurringCampaign(id);
    }
  }, [id]);

  const handleRecurringCampaignPause = async () => {
    try {
      setPauseLoading(true);
      await axiosPatch(`/recurring-campaigns/${id}`, {
        status: isActive ? RecurringCampaignStatusEnum.PAUSED : RecurringCampaignStatusEnum.ACTIVE
      });
      await getRecurringCampaign(id ?? '');

      addNotification({
        header: !isActive ? 'Campaign resumed successfully' : 'Campaign paused successfully',
      });

    } catch (err) {
      console.error(err);
    } finally {
      setPauseLoading(false);
    }
    setPauseLoading(false);
  };

  const getRecurringCampaign = async (id: string) => {

    const recurringCampaign = await axiosGet<IHttpResponse<IRecurringCampaign>>(`/recurring-campaigns/${id}`);
    setRecurringCampaign(recurringCampaign.data);

    const client = await axiosGet<IHttpResponse<IRecurringCampaign>>(`/clients/${recurringCampaign.data.clientId}`);
    setClientName(client.data.name);

    const providerName = await axiosGet<IHttpResponse<string>>(`/providers/${recurringCampaign.data.providerId}`);
    setProviderName(convertProviderName(providerName.data));

  };

  const handleRecurringCampaignDelete = async () => {
    setPauseLoading(true);
    try {
      await axiosPatch(`/recurring-campaigns/${id}/delete`, {});
      addNotification({
        header: 'Recurring Campaign Deleted'
      });
      navigate('/recurring-campaigns/');
    } catch (err) {
      addNotification({
        header: 'Recurring Campaign Deleted Failed',
      });
    }
    finally {
      setPauseLoading(false);
    }
  };

  const convertProviderName = (providerName: string) => {
    switch (providerName) {
      case ProviderName.TELNYX_TOLL_FREE:
      case ProviderName.BANDWIDTH_TOLL_FREE:
        return 'Toll Free';
      case ProviderName.TELNYX_10DLC:
      case ProviderName.BANDWIDTH_10DLC:
      case ProviderName.SINCH_10DLC:
        return '10DLC';
      case ProviderName.SINCH_SHORT_CODE:
        return 'Short Code';
      default:
        return '-';
    }
  };

  const convertTimeName = (frequency: string) => {
    switch (frequency) {
      case RecurringCampaignFrequencyEnum.DAILY:
        return 'everyday';
    }
  };

  function getFormattedDate(date: Date) {

    const year = new Date(date).getFullYear();
    const month = (1 + new Date(date).getMonth()).toString().padStart(2, '0');
    const day = new Date(date).getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  }

  const getScheduleMessage = (recurringCampaign: IRecurringCampaign) => {
    const audiences = recurringCampaign.audiences ?? [];
    const sendingStartsAt = recurringCampaign.sendingStartsAt;
    const startsAt = recurringCampaign.startsAt;
    const endsAt = recurringCampaign.hasNoEndDate ? recurringCampaign.endsAt : undefined;

    if (isEmpty(audiences) || !sendingStartsAt || !startsAt) {
      return '';
    }

    const audienceNames = audiences?.map(audience => audience?.name) ?? [];
    const audienceNamesMessage = getOxfordCommaString(audienceNames);

    const startDate = getFormattedDate(startsAt);
    const time = new Date(sendingStartsAt).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric'
    });

    if (endsAt) {
      const endDate = getFormattedDate(endsAt);
      return `Your messages are scheduled to send to ${audienceNamesMessage} at ${time} everyday, from ${startDate} to ${endDate}.`;
    } else {
      return `Your messages are scheduled to send to ${audienceNamesMessage} at ${time} everyday, from ${startDate}.`;
    }
  };


  return (<>   <div className="px-20">
    <h1 className="flex items-center text-black basis-full">
      <div className='flex flex-row items-center justify-center'>
        <div>{recurringCampaign?.name}</div>
      </div>
      <Button className="ml-auto" onClick={() => navigate('/recurring-campaigns/')} variant={ButtonVariantEnum.SECONDARY}>
        BACK
      </Button>
    </h1>
    {recurringCampaign && (
      <h3 className="mt-2 font-semibold text-wc-blue">
        {getScheduleMessage(recurringCampaign)}
      </h3>
    )
    }

  </div>
    <div className="flex items-center px-20 pt-8 space-x-4">
      <h4 className="text-dark-gray">View:</h4>
      <Chip color={'bg-glacier'} active={!showMetrics} onClick={() => setShowMetrics(false)}>
        Campaign Details
      </Chip>
      <Chip color={'bg-glacier'} active={showMetrics} onClick={() => setShowMetrics(true)}>
        Performance Metrics
      </Chip>
    </div>
    <div className="flex px-20 pb-4 mt-8">
      <div className="basis-2/3">
        {!showMetrics ? (
          <div className="space-y-8">
            {/* HEADER */}
            <div className="flex">
              <h2>Campaign Details</h2>
            </div>
            {/* BASIC INFO */}
            <div className="flex details-row">
              <Static shimmer={10} title="CLIENT">
                {clientName}
              </Static>
              <Static shimmer={10} title="STATUS">
                {recurringCampaign?.status}
              </Static>
            </div>
            {/* START/END DATE */}
            <div className="flex details-row">
              <Static shimmer={10} title="START DATE">
                {recurringCampaign?.startsAt ? moment(recurringCampaign?.startsAt).format('L, LT') : ''}
              </Static>
              <Static shimmer={10} title="END DATE">
                {recurringCampaign?.startsAt ? moment(recurringCampaign?.endsAt).format('L, LT') : ''}
              </Static>
            </div>
            {/* MESSAGE/NUMBER TYPE */}
            <div className="flex details-row">
              <Static shimmer={10} title="MESSAGE">
                {recurringCampaign?.messageType}
              </Static>
              <Static shimmer={10} title="NUMBER">
                {providerName}
              </Static>
            </div>
            {/* LISTS */}
            <div className="flex details-row">
              <Static shimmer={10} title="Audiences">
                {recurringCampaign?.audiences?.length > 0 && (
                  <ol>
                    {recurringCampaign?.audiences?.map((cl, index) => (
                      <li key={cl.id}>{index + 1}. {cl.name}</li>
                    ))}
                  </ol>
                )}
              </Static>
              <Static shimmer={10} title="Suppressions">
                {recurringCampaign?.suppressions?.length > 0 ? (
                  <ol>
                    {recurringCampaign?.suppressions?.map((cl, index) => (
                      <li key={cl.id}>{index + 1}. {cl.name}</li>
                    ))}
                  </ol>
                ) : (<ol>-</ol>)
                }
              </Static>
              <Static shimmer={10} title="EXTERNAL ID">
                {!!recurringCampaign && (recurringCampaign?.externalId || '-')}
              </Static>
            </div>
            {/* CLICK TRACKING */}
            <div className="flex flex-wrap">
              <div className="basis-1/2">
                <GlacierCheckbox
                  id="click-tracking"
                  label="Enable Click Tracking"
                  readOnly
                  checked={recurringCampaign?.clickTrackingEnabled ?? false}
                />
              </div>
              <div className="basis-1/2">
              </div>
              {recurringCampaign?.clickTrackingEnabled && (
                <>
                  <div className="mt-4 basis-1/2">
                    <Static shimmer={10} title="URL">
                      {recurringCampaign?.url}
                    </Static>
                  </div>
                  <div className="mt-4 basis-1/2">
                    <Static shimmer={10} title="DOMAIN">
                      {recurringCampaign.domain}
                    </Static>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="space-y-8">
            {/* HEADER */}
            <div className="flex">
              <h2>Performance Metrics</h2>
            </div>
            {/* METRICS L1 */}
            <div>
              <div className="flex justify-between mb-8">
                <MetricCard
                  title="DELIVERABILITY RATE"
                  //_export
                  loading={!recurringCampaign}
                  metric={((recurringCampaign?.messagesDelivered ?? 0) / (recurringCampaign?.messagesSent ?? 0)) * 100 || 0}
                // TODO: Export failed messages
                />
                {/* <MetricCard
                  title="CLICK RATE"
                  loading={!recurringCampaign}
                  _export={!!recurringCampaign}
                  metric={((recurringCampaign?.linkClicks ?? 0) / (recurringCampaign?.messagesDelivered ?? 0)) * 100 || 0}
                  onExport={() => handleExportLinkClicks(recurringCampaign?.id, recurringCampaign?.name, recurringCampaign?.startsAt)}
                  exportLoading={clickRateLoading}
                  // if the start date of the campaign was 30 or more days ago. disable export data button for click rate metric
                  disabled={recurringCampaign && Math.round((new Date().getTime() - new Date(recurringCampaign.startsAt).getTime()) / (1000 * 3600 * 24)) > 30}
                />
                <MetricCard
                  title="OPT-OUT RATE"
                  loading={!recurringCampaign}
                  _export={!!recurringCampaign}
                  exportLoading={optoutRateLoading}
                  metric={
                    (Object.values(recurringCampaign?.optOuts ?? {}).reduce((x, y) => x + y, 0) /
                      (recurringCampaign?.messagesDelivered ?? 0)) *
                    100 || 0
                  }
                  onExport={() => handleExportOptouts(recurringCampaign)}
                /> */}
              </div>
              <div
                className="rounded-2.5xl bg-white flex items-center p-4 justify-between"
                style={{ boxShadow: '0px 0px 70px 0px #E9EBF0' }}
              >
                <h3 className="ml-8">Reply Rate</h3>
                {/* <MetricCard
                  title="ALL RESPONSES"
                  flat
                  metric={recurringCampaign?.replies ?? 0}
                  loading={!campaign}
                  _export={!!campaign}
                  onExport={() => handleExportConversations(recurringCampaign?.id, recurringCampaign?.name, false)}
                  exportLoading={responseLoading}
                />
                <MetricCard
                  title="REPLIES WITHOUT OPT-OUTS"
                  flat
                  _export={!!campaign}
                  onExport={() => handleExportConversations(recurringCampaign?.id, recurringCampaign?.name, true)}
                  loading={!campaign}
                  metric={(recurringCampaign?.replies ?? 0) - (recurringCampaign?.optOuts?.REPLY_AUTOMATIC ?? 0) || 0}
                  exportLoading={repliesOptoutsLoading}
                /> */}
              </div>
            </div>
            {/* MESSAGE STATUS */}
            <div className="flex flex-wrap justify-between pt-12 pb-8 px-16 rounded-2.5xl bg-ice border-2 border-white">
              <h2 className="m-auto mb-12 text-center text-black basis-full">Message Status</h2>
              <div>
                <div className="flex items-center">
                  {/* Confirmed / Scheduled */}
                  <h5 className="mr-2 text-right text-dark-gray">SCHEDULED</h5>
                  <div
                    className={`flex grow w-36 basis-1/2 h-2 overflow-hidden text-xs rounded ${recurringCampaign?.messagesScheduled === 0 ? 'bg-light-gray' : 'bg-mint'
                      }`}
                  >
                    <>
                      <div
                        style={{
                          width: `${Math.min(
                            Math.ceil(
                              ((recurringCampaign?.messagesConfirmed ?? 0) / (recurringCampaign?.messagesConfirmed ?? 100)) * 100
                            ),
                            100
                          )}%`,
                        }}
                        className={`shadow-none bg-grass`}
                      ></div>
                    </>
                  </div>
                </div>
                <h5
                  className="mt-0.5 text-right truncate text-medium-gray dark:text-slate-400 basis-full"
                  title="Scheduled"
                >
                  {recurringCampaign?.messagesConfirmed?.toLocaleString()} / {recurringCampaign?.messagesScheduled?.toLocaleString()}
                </h5>
              </div>
              <div>
                <div className="flex items-center">
                  {/* Sent / Delivered / Failed */}
                  <h5 className="min-w-[65px] text-right mt-px mr-2 text-dark-gray">DELIVERY</h5>
                  <div
                    className={`flex grow w-36 basis-1/2 h-2 overflow-hidden text-xs rounded justify-end ${recurringCampaign?.messagesSent === 0 ? 'bg-light-gray' : 'bg-mint'
                      }`}
                  >
                    <>
                      {/* <div
                        style={{
                          width: `${Math.min(
                            Math.ceil(
                              ((recurringCampaign?.messagesDelivered ?? 0) / (recurringCampaign?.messagesDelivered ?? 100)) * 100
                            ),
                            100
                          )}%`,
                        }}
                        className={`shadow-none bg-grass`}
                      ></div> */}
                      <div
                        style={{
                          width: `${Math.min(
                            Math.ceil(((recurringCampaign?.messagesFailed ?? 0) / (recurringCampaign?.messagesSent ?? 100)) * 100),
                            100
                          )}%`,
                        }}
                        className={`flex flex-col justify-center text-center text-white shadow-none bg-red-400 whitespace-nowrap`}
                      ></div>
                    </>
                  </div>
                </div>
                <h5
                  className="mt-0.5 text-right truncate text-medium-gray dark:text-slate-400 basis-full"
                  title="Sent / Delivered / Failed"
                >
                  {recurringCampaign?.messagesSent?.toLocaleString()} / {recurringCampaign?.messagesDelivered?.toLocaleString()} /{' '}
                  {recurringCampaign?.messagesFailed?.toLocaleString()}
                </h5>
              </div>
            </div>
            {/* TEST MESSAGE STATUS */}
            {/* {(campaignHealthCheckData?.data?.testMessages?.length ?? 0) > 0 && (
              <div className="flex flex-wrap">
                <h2 className="flex items-center mr-2">Test Message Status</h2>
                <div className="mt-1">
                  {campaignHealthCheckData?.data?.testMessages?.filter((chcd) => chcd.errorType)?.length ?? 0 > 0 ? (
                    <Exclamation />
                  ) : (
                    <Radio />
                  )}
                </div>
                <StaticTableList
                  className="mt-8 basis-full"
                  columns={[
                    {
                      headerName: 'NUMBER',
                      fieldName: 'toNumber',
                      renderColumn: (row) => (
                        <div className="flex items-center whitespace-nowrap">
                          {formatPhoneNumber(row.toNumber)}
                          {!row.errorCode && (
                            <div className="mx-2">
                              <Radio />
                            </div>
                          )}
                        </div>
                      ),
                    },
                    {
                      headerName: 'CARRIER',
                      fieldName: 'carrierName',
                    },
                    {
                      headerName: 'ERROR CODE',
                      fieldName: 'errorCode',
                    },
                    {
                      headerName: 'ERROR TYPE',
                      fieldName: 'errorType',
                    },
                    ...(isSuperAdmin
                      ? [
                        {
                          headerName: 'PROVDER ERROR CODE',
                          fieldName: 'providerErrorCode',
                        },
                        {
                          headerName: 'PROVIDER ERROR TYPE',
                          fieldName: 'providerErrorType',
                        },
                      ]
                      : []),
                  ]}
                  data={campaignHealthCheckData?.data?.testMessages ?? []}
                />
              </div>
            )}
            {(campaignErrorData?.data?.errorCodes?.length ?? 0) > 0 && (
              <div className="flex flex-wrap w-full">
                <h2 className="flex items-center w-full mb-4 text-flamingo">Message Failure Log</h2>
                <StaticTableList
                  className="w-full"
                  columns={getErrorColumns(false)}
                  data={campaignErrorData?.data?.errorCodes ?? []}
                />
              </div>
            )}
            {isSuperAdmin && (campaignErrorData?.data?.internalErrorCodes?.length ?? 0) > 0 && (
              <div className="flex flex-wrap w-full">
                <h2 className="flex items-center w-full mb-4 text-flamingo">Admin Message Failure Log</h2>
                <StaticTableList
                  className="w-full"
                  columns={getErrorColumns(false)}
                  data={campaignErrorData?.data?.internalErrorCodes ?? []}
                />
              </div>
            )} */}
          </div>
        )}
      </div>
      <div className="flex flex-col ml-auto">
        <MessagePreview campaign={recurringCampaign} domain={recurringCampaign?.domain} customDataPoints={customDataPoints} />
        {recurringCampaign?.messageType === MessageTypeEnum.MMS && (
          <div className="flex">
            <div className="pl-8 mx-auto mt-1">
              <a href={recurringCampaign?.mediaUrl!} target="_blank" download className="text-wc-blue hover:underline">
                Download Media File
              </a>
            </div>
          </div>
        )}
        <div className="pl-8 mx-auto mt-2 body-text-small">
        </div>
        {/* PAUSE CAMPAIGN */}
        {(!!recurringCampaign && recurringCampaign.status === RecurringCampaignStatusEnum.PAUSED) ?
          (<div className="pl-8 mx-auto mt-4 flex items-center justify-between space-x-4">
            <Button
              onClick={() => setShowModal(true)}
              isLoading={pauseLoading}
              variant={ButtonVariantEnum.SECONDARY}
              disabled={!recurringCampaign}
            >
              RESUME
            </Button>

            <Button
              onClick={() => setshowDeleteModal(true)}
              isLoading={pauseLoading}
              variant={ButtonVariantEnum.DELETE}
              disabled={!recurringCampaign}
            >
              DELETE
            </Button>
          </div>) :
          (<div className="pl-8 mx-auto mt-4 flex flex-col space-y-4">
            <Button
              onClick={() => setShowModal(true)}
              isLoading={pauseLoading}
              variant={ButtonVariantEnum.SECONDARY}
              disabled={!recurringCampaign}
            >
              PAUSE RECURRING CAMPAIGN
            </Button>
          </div>)

        }
      </div>
    </div>
    <RecurringCampaignPauseResumeModal
      isCampaignActive={isActive ?? false}
      showModal={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      onSuccess={() => {
        handleRecurringCampaignPause();
        setShowModal(false);
      }}
      onCancel={() => {
        setShowModal(false);
      }}
    />

    <RecurringCampaignDeleteModal
      showModal={showDeleteModal}
      onClose={() => {
        setshowDeleteModal(false);
      }}
      onSuccess={() => {
        handleRecurringCampaignDelete();
        setshowDeleteModal(false);
      }}
      onCancel={() => {
        setshowDeleteModal(false);
      }}
    />
  </>);
};



export default RecurringCampaignsDetails;