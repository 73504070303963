import { Button, ButtonVariantEnum, Modal } from '@Wonder-Cave/ui';

interface IRecurringCampaignDeleteModalProps {
  showModal: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onCancel: () => void;
}

const RecurringCampaignDeleteModal = ({
  showModal,
  onClose,
  onSuccess,
  onCancel,
}: IRecurringCampaignDeleteModalProps) => {
  return (
    <Modal title="Are you sure?" show={showModal} onClose={onClose}>
      <h3 className="px-8 text-center">
        Once deleted, new daily recurring campaigns will no longer be created.
      </h3>
      <div className="flex justify-center mt-8 mb-4">
        <Button variant={ButtonVariantEnum.SECONDARY} onClick={onCancel} className="mr-4 uppercase">
          CANCEL
        </Button>
        <Button onClick={onSuccess} variant={ButtonVariantEnum.DELETE} className="uppercase">
          DELETE RECURRING CAMPAIGN
        </Button>
      </div>
    </Modal>
  );
};

export default RecurringCampaignDeleteModal;
