import { MessageStatusEnum } from '@shared/enums';
import { Auth0Role, IGetTestMessageResponse, ITestMessage } from '@shared/models';
import { Button, ButtonVariantEnum, Checkbox, LoaderIcon, RightArrowIcon, StaticTableList } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import { axiosGet, axiosPost } from 'apps/a2p-portal/src/authAxios';
import { NotificationType, useNotifications } from 'apps/a2p-portal/src/contexts/NotificationContext';
import useRoles from 'apps/a2p-portal/src/hooks/useRoles';
import { groupBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import TestNumbersDropdown from '../../../shared/Form/Dropdowns/TestNumbersDropdown';
import MessagePreview from '../../../shared/Form/MessagePreview';
import { Radio } from '../../../shared/Icons/Radio';
import { IPreviewProps } from './types';

const Preview = (props: IPreviewProps) => {
  const [messages, setMessages] = useState<Array<ITestMessage>>([]);
  const [numbers, setNumbers] = useState<number[]>([]);
  const [error, setError] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [date, setDate] = useState<Date>();
  const [messagesScheduled, setMessagesScheduled] = useState(0);
  const isSuperAdmin = useRoles([Auth0Role.A2P_SUPER_ADMIN]);
  const { addNotification } = useNotifications();

  const initTestMessages = async () => {
    //Would rather not hit an if statement for each call, so this is a new function for the first call
    setLoading(true);
    try {
      const messageResponse = await axiosGet<IGetTestMessageResponse>(`/recurring-campaigns/${props.values?.id}/test-messages`);
      const messages = messageResponse?.data?.testMessages;
      const scheduled = messageResponse?.data?.testMessages.filter(tm => tm.status === MessageStatusEnum.SCHEDULED).length;
      if (messages?.length > 0) {
        setNumbers(messages.map((m) => Number(m.toNumber)));
        setMessages(messages);
      }
      setMessagesScheduled(scheduled);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getTestMessages = async () => {
    try {
      if (!sending) {
        const messageResponse = await axiosGet<IGetTestMessageResponse>(`recurring-campaigns/${props.values?.id}/test-messages`);
        // has this changed while waiting
        if (!sending) {
          setMessages(messageResponse.data?.testMessages);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = () => {
    setSelectAll(!selectAll);
  };


  useEffect(() => {
    if (props.values?.id && !sending) {
      initTestMessages();

      const interval = setInterval(() => {
        getTestMessages();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, []);

  const sendTestMessages = async () => {
    if (numbers.length > 0) {
      let response;
      setSending(true);
      setMessagesScheduled(0);
      if (!props.values?.id) {
        response = await props.saveDraft(props, false);
      }
      setDate(new Date()); // Record the date last clicked
      try {
        await axiosPost(`recurring-campaigns/${response?.data?.recurringCampaign?.id ?? props.values?.id}/test-messages`, {
          testNumbers: [...new Set(numbers?.map((n) => Number(n)))],
        });
        setMessagesScheduled(numbers.length);
      } catch (e: any) {
        console.error(e);
        addNotification({
          header: 'Error',
          content: e.response?.data?.message ?? 'Unexpected error creating test messages.',
          type: NotificationType.FAILURE,
        });
      } finally {
        setSending(false);
      }
    } else {
      setError(true);
    }
  };

  const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  };

  const groupedMessages = groupBy(messages, 'status');

  // const sentCount =
  //   (groupedMessages['SENT']?.length ?? 0) +
  //   (groupedMessages['DELIVERED']?.length ?? 0) +
  //   (groupedMessages['FAILED']?.length ?? 0);
  return (
    <div className="flex">
      <div className="flex flex-col basis-1/4">
        <MessagePreview campaign={props.values} domain={props.values?.domain} customDataPoints={props.customDataPoints} />
        <div className="status-box rounded-large border-white border-solid border-[5px] flex flex-col mt-8 mb-4 max-w-lg ml-8">
          <h3 className="mx-auto mt-6 text-wc-blue">Test Message Progress</h3>
          <h5 className="mx-auto mt-2 text-dark-gray">STATUS: {messages[0]?.status ?? 'SENT'}</h5>
          <div className="mx-8 mt-6">
            <div className="flex items-center mt-4">
              <h5 className="min-w-[65px] text-right mt-px mr-2 text-dark-gray">DELIVERY</h5>
              <div
                className={`flex grow h-2 overflow-hidden text-xs rounded justify-end ${messagesScheduled === 0 ? 'bg-light-gray dark:bg-slate-400' : 'bg-mint'}`}
              >
                <>
                  <div
                    style={{
                      width: `${Math.min(
                        Math.ceil(((groupedMessages['DELIVERED']?.length ?? 0) / (messagesScheduled ?? 100)) * 100),
                        100
                      )}%`,
                    }}
                    className={`flex flex-col justify-center text-center text-white bg-grass shadow-none bg-grass-400 whitespace-nowrap`}
                  ></div>
                  <div
                    style={{
                      width: `${Math.min(
                        Math.ceil(((groupedMessages['FAILED']?.length ?? 0) / (messagesScheduled ?? 100)) * 100),
                        100
                      )}%`,
                    }}
                    className={`flex flex-col justify-center text-center text-white bg-flamingo shadow-none whitespace-nowrap`}
                  ></div>
                </>
              </div>
            </div>
            <h5
              className="mt-1 text-right truncate text-medium-gray dark:text-slate-400 basis-full"
              title="Sent / Delivered / Failed"
            >
              {messagesScheduled.toLocaleString()} / {(groupedMessages['DELIVERED']?.length ?? 0).toLocaleString()} /{' '}
              {(groupedMessages['FAILED']?.length ?? 0).toLocaleString()}
            </h5>
          </div>
          <div className="mx-auto my-8 body-text-small text-medium-gray">Sent: {moment(date).format('L, LT')}</div>
        </div>
      </div>
      <div className="flex flex-col ml-10 basis-1/2">
        <div>
          <h2 className="mt-12 mb-2">Preview your message</h2>
          <div className="mt-8 mr-18 ">
            <div className='flex justify-between mb-2'>
              <label className='text-medium-gray body-text-small'> SELECT TEST MESSAGE CONTACTS
              </label>
              <div className=''>
                <Checkbox
                  id="selectAll"
                  name="selectAll"
                  label="Select All"
                  title={'Requires Test Numbers to be added'}
                  checked={selectAll}
                  onChange={handleChange}
                />
              </div>
            </div>
            <TestNumbersDropdown
              value={numbers}
              loading={loading}
              onChange={(value) => {
                setNumbers((value as IDropdownValue[]).map((v) => v?.additionalData?.number));
                setError(false);
              }}
              showError={false}
              client={props.values?.client ?? (props as any).client?.value ?? ''}
              selectAll={selectAll} />

          </div>

        </div>
        <div className={`${error ? 'text-flamingo' : 'text-medium-gray'} -mt-2 body-text-small`}>Select up to 20 contacts to send a test message.</div>
        <div className="flex items-center mt-8">
          <Button
            trailingIcon={<RightArrowIcon />}
            variant={ButtonVariantEnum.GLACIER}
            onClick={sendTestMessages}
            type="button"
            isLoading={props.sending}
          >
            Send Test Message
          </Button>
          {props.saveLoading && <div className="flex items-center ml-6">
            <LoaderIcon />
            <p className="mb-px ml-3 body-text-bold text-medium-gray">
              Saving draft...
            </p>
          </div>}
        </div>
        {!props.sending && messages?.length > 0 && (
          <StaticTableList
            className="mt-8 basis-full"
            columns={[
              {
                headerName: 'NUMBER',
                fieldName: 'toNumber',
                renderColumn: (row) => (
                  <div className="flex items-center whitespace-nowrap">
                    {formatPhoneNumber(row.toNumber)}
                    {!row.errorCode && (
                      <div className="mx-2">
                        <Radio />
                      </div>
                    )}
                  </div>
                ),
              },
              {
                headerName: 'CARRIER',
                fieldName: 'carrierName',
              },
              {
                headerName: 'ERROR CODE',
                fieldName: 'errorCode',
              },
              {
                headerName: 'ERROR TYPE',
                fieldName: 'errorType',
              },
              ...(isSuperAdmin
                ? [
                  {
                    headerName: 'PROVDER ERROR CODE',
                    fieldName: 'providerErrorCode',
                  },
                  {
                    headerName: 'PROVIDER ERROR TYPE',
                    fieldName: 'providerErrorType',
                  },
                ]
                : []),
            ]}
            data={messages} />
        )}

      </div>
    </div>
    //     { submissionError && <h3 className='mb-4 text-right text-red-600'>{submissionError}</h3>; }
    //   </div > 
    //   </div >
    // </div >
  );
};

export default Preview;
