import { ClockIcon } from '@heroicons/react/outline';
import { RecurringCampaignStatusEnum } from '@shared/enums';
import { FilterDataTypeEnum, FilterOperatorEnum, IRecurringCampaignOverview, ISearchFilter, ISearchRequest } from '@shared/models';
import { capitalize } from '@shared/services';
import { Button } from '@Wonder-Cave/ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClientContext } from '../../contexts/ClientContext';
import useRecurringCampaigns from '../../hooks/useRecurringCampaigns';
import NewTextInput from '../shared/Form/NewTextInput';
import { Table } from '../shared/Table/Table';
import { defaultRecurringCampaignsTableOptions, getColumns } from './types';

const RecurringCampaigns = () => {
  // const { addNotification } = useNotifications();
  // const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  // const [campaignCreating, setCampaignCreating] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  // const [deleteLoading, setDeleteLoading] = useState(false);
  // const [selectedItem, setSelectedItem] = useState<ICampaignDetails>();
  const [searchText, setSearchText] = useState('');
  const { selectedClientId } = useClientContext();
  const [tableOptions, setTableOptions] = useState({
    ...defaultRecurringCampaignsTableOptions,
  } as ISearchRequest);
  const [{ data: campaignData, loading: campaignLoading }, refetch] = useRecurringCampaigns({
    ...tableOptions,
    clientId: selectedClientId,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const searchText = tableOptions.filters.find((filter) => filter.fieldName === 'name')?.value;

    handleRefetch();

    setSearchText(searchText ?? '');
  }, [tableOptions]);

  const goToCreateCampaign = () => {
    navigate('/recurring-campaigns/create');
  };

  const columns = getColumns(
    {
      name: (item: IRecurringCampaignOverview) => {

        if (item.status === RecurringCampaignStatusEnum.DRAFT) {
          navigate(`/recurring-campaigns/${item.id}/edit`);
        } else {
          navigate(`/recurring-campaigns/${item.id}`);
        }

      },
    },
    {
      name: (item: IRecurringCampaignOverview) => {
        if (!item) {
          return;
        }

        const sendTimeParts = new Date(item.sendingStartsAt).toLocaleTimeString().split(' ');
        const sendTimeClockStr = sendTimeParts[0].split(':').slice(0, -1).join(':');
        return <>
          <div className='mb-2 text-lg text-sky-600 hover:text-sky-900 hover:underline'>{item.name}</div>
          <div className='flex flex-row items-center text-xs text-medium-gray fill-medium-gray'>
            <ClockIcon width={15} className='mr-2' />
            <span>{capitalize(item.frequency.toLowerCase())} at {sendTimeClockStr} {sendTimeParts[1]}</span>
          </div>
        </>;
      },
      duration: (item: IRecurringCampaignOverview) => item && `${new Date(item.startsAt).toLocaleDateString()} - ${new Date(item.endsAt).toLocaleDateString()}`
    }
  );

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (error) { }
  };

  const handleClear = () => {
    const newSearchOptions = { ...tableOptions };
    newSearchOptions.filters = newSearchOptions.filters.filter((filter) => filter.fieldName !== 'name');
    setTableOptions(newSearchOptions);
  };

  const checkForClear = () => {
    const sanitizedSearchText = searchText.replace(/[()-\s]/g, '');

    const currSearchText = tableOptions.filters.find((filter) => filter.fieldName === 'name')?.value;

    if (currSearchText && !sanitizedSearchText) {
      handleClear();
    }
  };

  const handleSearch = () => {
    const sanitizedSearchText = searchText.trim();

    const currSearchText = tableOptions.filters.find((filter) => filter.fieldName === 'name')?.value;

    // Check for no-change
    if (currSearchText === sanitizedSearchText) {
      return;
    }

    // Check for clear
    if (currSearchText && !sanitizedSearchText) {
      return handleClear();
    }

    let searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'name',
      operator: FilterOperatorEnum.CONTAINS,
      value: sanitizedSearchText,
    };

    const newSearchOptions = { ...tableOptions };
    newSearchOptions.filters = newSearchOptions.filters.filter((filter) => filter.fieldName !== 'name');
    newSearchOptions.pagination = defaultRecurringCampaignsTableOptions.pagination;
    newSearchOptions.filters.push(searchFilter);

    setTableOptions(newSearchOptions);
  };

  return (
    <>
      <h2 className="header-page">
        Recurring Campaigns
        <div className='flex flex-row'>
          <div className="flex mr-2">
            <NewTextInput
              onEnter={handleSearch}
              onBlur={checkForClear}
              onClear={handleClear}
              className='ml-auto mr-4 w-72'
              name="search"
              search
              placeholder='Search'
              onChange={(e) => setSearchText(e.target.value)}
              trailingIconClassName='mr-0'
              value={searchText}
              clearable
            />
          </div>
          <Button onClick={() => goToCreateCampaign()}>Create Recurring Campaign</Button>
        </div>
      </h2>
      <Table
        columns={columns}
        items={campaignData?.records}
        count={campaignData?.totalCount}
        loading={campaignLoading}
        skipEnumFormattingColumns={['messageType']}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={(request) => setTableOptions(request)}
        paginate
        onRefresh={handleRefetch}
      />
      {/*

      TODO - implement RecurringCampaignDetailsPanel

      <CampaignDetailsPanel
        handleSubmit={handleSubmit}
        handleDelete={handleCampaignDelete}
        handleActiveToggle={handleCampaignActiveToggle}
        handleClone={handleClone}
        deleteLoading={deleteLoading}
        pauseLoading={updateLoading}
        cloneLoading={cloneLoading}
        show={showDetailsPanel}
        onClosePanel={closeDetailsPanel}
        selectedItem={selectedItem}
        saveLoading={campaignCreating}
        errorMessage={errorMessage}
      /> */}
    </>
  );
};

export default RecurringCampaigns;
