import { IDomainsEntity } from '@shared/models';
import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';

interface IDomainsDropdownProps {
  value: string;
  onChange: (newValue: IDropdownValue | undefined) => void;
  onBlur: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  options: IDomainsEntity[] | undefined;
}

const NewDomainsDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  options,
  errorMessage,
  showError,
}: IDomainsDropdownProps): JSX.Element => {
  const domains =
    options?.map((domain) => ({
      label: domain.url ?? '',
      value: domain.id ?? '',
    })) ?? [];

  return (
    <Autocomplete
      label="SHORTENED URL"
      value={domains.find((domain) => domain.value === value)}
      options={domains}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
      search={false}
    />
  );
};

export default NewDomainsDropdown;
